<template>
  <v-dialog
    transition="scale-transition"
    :max-width="600"
    persistent
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card>
      <o-form
        ref="form"
        @submit="submit"
        @close="$emit('input', false)"
      >
        <v-card-title class="primary--text d-flex justify-space-between">
          <span>{{ $t('messages.views.admin.components.regulation.timelines.timelineFormModal.title.' + mode) }}</span>
          <v-btn
            icon
            @click="close"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <o-text-field
                v-model="form.name"
                :rules="timelineValidation.name"
                label="labels.timeline.name"
                prepend-icon="mdi-rename-box"
                dense
                autofocus
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-textarea
                v-model="form.description"
                :rules="timelineValidation.description"
                label="labels.timeline.description"
                prepend-icon="mdi-information"
                rows="1"
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-data-table
                :headers="timelineSlotsTable.headers"
                :items="form.timelineSlots"
                disable-filtering
                disable-pagination
                fixed-header
                hide-default-footer
                :no-data-text="$t('messages.views.admin.components.regulation.timelines.timelineFormModal.noTimelineSlot')"
                dense
                sort-by="startingAt"
                :items-per-page="-1"
              >
                <template v-slot:header.action>
                  <v-btn
                    icon
                    @click="addTimelineSlot"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <template v-slot:item.startingAt="{ index, item }">
                  <o-time-field
                    v-model="item.startingAt"
                    :rules="timelineSlotValidation.endingAt"
                    hide-details
                    dense
                    flat
                    solo
                    background-color="transparent"
                  />
                </template>

                <template v-slot:item.endingAt="{ item }">
                  <o-time-field
                    v-model="item.endingAt"
                    :rules="timelineSlotValidation.endingAt"
                    hide-details
                    dense
                    flat
                    solo
                    background-color="transparent"
                  />
                </template>

                <template v-slot:item.trafficLightPlan="{ item }">
                  <o-traffic-light-plan-select
                    v-model="item.trafficLightPlan"
                    :traffic-light-plans="trafficLightPlans"
                    :rules="timelineSlotValidation.trafficLightPlan"
                    align="center"
                    disable-required-mark
                    dense
                    hide-details
                    solo
                    flat
                    background-color="transparent"
                  />
                </template>

                <template v-slot:item.action="{ index }">
                  <v-btn
                    icon
                    @click="removeTimelineSlot(index)"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <v-row v-if="!isFormComplete">
            <v-col>
              <v-alert
                type="error"
              >
                {{ $t('messages.views.admin.components.regulation.timelines.timelineFormModal.formIsNotFilled') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row v-else-if="areTimelineSlotsOverlapping">
            <v-col>
              <v-alert
                type="error"
              >
                {{ $t('messages.views.admin.components.regulation.timelines.timelineFormModal.timelineSlotsOverlapping') }}
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <o-submit v-if="isFormComplete && !areTimelineSlotsOverlapping">
                {{ $t('messages.views.admin.components.regulation.timelines.timelineFormModal.submit.' + mode) }}
              </o-submit>
            </v-col>
          </v-row>
        </v-card-text>
      </o-form>
    </v-card>
  </v-dialog>
</template>

<script>
  import { hydrate } from '@utils/form';

  export default {
    props: {
      timeline: {
        type: Object,
        default: null,
      },
      trafficLightPlans: {
        type: Array,
        required: true,
      },
    },

    data: function () {
      return {
        show: true,
        form: {
          name: '',
          description: '',
          timelineSlots: [],
        },
        timelineValidation: require('@/validation/entities/timeline.json'),
        timelineSlotValidation: require('@/validation/entities/timelineSlot.json'),
        mode: 'create',
        timelineSlotsTable: {
          headers: [
            {
              value: 'startingAt',
              text: this.$t('labels.timelineSlot.startingAt'),
              sortable: false,
              align: 'center',
            },
            {
              value: 'endingAt',
              text: this.$t('labels.timelineSlot.endingAt'),
              sortable: false,
              align: 'center',
            },
            {
              value: 'trafficLightPlan',
              text: this.$t('labels.timelineSlot.trafficLightPlan'),
              sortable: false,
              align: 'center',
            },
            {
              value: 'action',
              sortable: false,
              align: 'center',
            },
          ],
        },
      };
    },

    computed: {
      isFormComplete () {
        for (let i = 0; i < this.form.timelineSlots.length; i++) {
          const timelineSlot = this.form.timelineSlots[i];
          if (timelineSlot.startingAt === null || timelineSlot.endingAt === null) {
            return false;
          }
        }

        return true;
      },
      areTimelineSlotsOverlapping () {
        for (let i = 0; i < this.form.timelineSlots.length; i++) {
          const timelineSlotA = this.form.timelineSlots[i];
          const startingAtA = this.timeToMinutes(timelineSlotA.startingAt);
          let endingAtA = this.timeToMinutes(timelineSlotA.endingAt);
          if (endingAtA === 0) {
            endingAtA = 24 * 60 * 60;
          }

          if (startingAtA >= endingAtA) {
            return true;
          }

          for (let j = i + 1; j < this.form.timelineSlots.length; j++) {
            const timelineSlotB = this.form.timelineSlots[j];
            const startingAtB = this.timeToMinutes(timelineSlotB.startingAt);
            let endingAtB = this.timeToMinutes(timelineSlotB.endingAt);
            if (endingAtB === 0) {
              endingAtB = 24 * 60 * 60;
            }

            if (
              (startingAtB >= startingAtA && startingAtB < endingAtA) ||
              (endingAtB > startingAtA && endingAtB <= endingAtA)
            ) {
              return true;
            }
          }
        }

        return false;
      },
    },

    created () {
      if (this.timeline !== null) {
        this.removeSeconds(this.timeline);
        hydrate(this.form, this.timeline);
        if (undefined !== this.timeline.id && this.timeline.id !== null) {
          this.mode = 'update';
        }
      }
    },

    methods: {
      removeSeconds (timeline) {
        for (const i in timeline.timelineSlots) {
          timeline.timelineSlots[i].startingAt = timeline.timelineSlots[i].startingAt.substr(0, 5);
          timeline.timelineSlots[i].endingAt = timeline.timelineSlots[i].endingAt.substr(0, 5);
        }
      },
      addSeconds (timeline) {
        for (const i in timeline.timelineSlots) {
          timeline.timelineSlots[i].startingAt += ':00';
          timeline.timelineSlots[i].endingAt += ':00';
        }
      },
      submit (scope) {
        if (!this.areTimelineSlotsOverlapping) {
          this.addSeconds(this.form);
          this.$emit('submit', this.form);
        }
      },
      close () {
        this.$refs.form.close();
      },
      removeTimelineSlot (index) {
        this.form.timelineSlots.splice(index, 1);
        this.$refs.form.onFormChanged();
      },
      addTimelineSlot () {
        const startingAt = this.guessBestStartingAtForNewTimelineSlot();
        this.form.timelineSlots.push({
          startingAt: startingAt,
          endingAt: this.minutesToTime(this.timeToMinutes(startingAt) + 60),
          trafficLightPlan: this.trafficLightPlans.length ? this.trafficLightPlans[0].id : null,
        });
        this.$refs.form.onFormChanged();
      },
      guessBestStartingAtForNewTimelineSlot () {
        let maxEndingAt = 0;
        for (const timelineSlot of this.form.timelineSlots) {
          if (timelineSlot.endingAt) {
            const time = this.timeToMinutes(timelineSlot.endingAt);
            if (time > maxEndingAt) {
              maxEndingAt = time;
            }
          }
        }

        return this.minutesToTime(maxEndingAt);
      },
      timeToMinutes (time) {
        const parts = time.split(':');

        return parseInt(parts[0]) * 60 + parseInt(parts[1]);
      },
      minutesToTime (time) {
        const hours = Math.floor(time / 60);
        const minutes = time - (hours * 60);

        return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
      },
    },
  };
</script>
