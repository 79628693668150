var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',_vm._g(_vm._b({attrs:{"transition":"scale-transition","max-width":600,"persistent":""}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',[_c('o-form',{ref:"form",on:{"submit":_vm.submit,"close":function($event){return _vm.$emit('input', false)}}},[_c('v-card-title',{staticClass:"primary--text d-flex justify-space-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('messages.views.admin.components.regulation.timelines.timelineFormModal.title.' + _vm.mode)))]),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('o-text-field',{attrs:{"rules":_vm.timelineValidation.name,"label":"labels.timeline.name","prepend-icon":"mdi-rename-box","dense":"","autofocus":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)],1),_c('v-row',[_c('v-col',[_c('o-textarea',{attrs:{"rules":_vm.timelineValidation.description,"label":"labels.timeline.description","prepend-icon":"mdi-information","rows":"1","dense":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.timelineSlotsTable.headers,"items":_vm.form.timelineSlots,"disable-filtering":"","disable-pagination":"","fixed-header":"","hide-default-footer":"","no-data-text":_vm.$t('messages.views.admin.components.regulation.timelines.timelineFormModal.noTimelineSlot'),"dense":"","sort-by":"startingAt","items-per-page":-1},scopedSlots:_vm._u([{key:"header.action",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.addTimelineSlot}},[_c('v-icon',[_vm._v("mdi-plus")])],1)]},proxy:true},{key:"item.startingAt",fn:function(ref){
var index = ref.index;
var item = ref.item;
return [_c('o-time-field',{attrs:{"rules":_vm.timelineSlotValidation.endingAt,"hide-details":"","dense":"","flat":"","solo":"","background-color":"transparent"},model:{value:(item.startingAt),callback:function ($$v) {_vm.$set(item, "startingAt", $$v)},expression:"item.startingAt"}})]}},{key:"item.endingAt",fn:function(ref){
var item = ref.item;
return [_c('o-time-field',{attrs:{"rules":_vm.timelineSlotValidation.endingAt,"hide-details":"","dense":"","flat":"","solo":"","background-color":"transparent"},model:{value:(item.endingAt),callback:function ($$v) {_vm.$set(item, "endingAt", $$v)},expression:"item.endingAt"}})]}},{key:"item.trafficLightPlan",fn:function(ref){
var item = ref.item;
return [_c('o-traffic-light-plan-select',{attrs:{"traffic-light-plans":_vm.trafficLightPlans,"rules":_vm.timelineSlotValidation.trafficLightPlan,"align":"center","disable-required-mark":"","dense":"","hide-details":"","solo":"","flat":"","background-color":"transparent"},model:{value:(item.trafficLightPlan),callback:function ($$v) {_vm.$set(item, "trafficLightPlan", $$v)},expression:"item.trafficLightPlan"}})]}},{key:"item.action",fn:function(ref){
var index = ref.index;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.removeTimelineSlot(index)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1)]}}])})],1)],1),(!_vm.isFormComplete)?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('messages.views.admin.components.regulation.timelines.timelineFormModal.formIsNotFilled'))+" ")])],1)],1):(_vm.areTimelineSlotsOverlapping)?_c('v-row',[_c('v-col',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.$t('messages.views.admin.components.regulation.timelines.timelineFormModal.timelineSlotsOverlapping'))+" ")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',[(_vm.isFormComplete && !_vm.areTimelineSlotsOverlapping)?_c('o-submit',[_vm._v(" "+_vm._s(_vm.$t('messages.views.admin.components.regulation.timelines.timelineFormModal.submit.' + _vm.mode))+" ")]):_vm._e()],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }